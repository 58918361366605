import React, { useState } from 'react';
import { AlertTriangle, ChevronLeft, ChevronRight } from 'lucide-react';

const AlertDialog = ({
  open,
  onOpenChange,
  title = "紧急通知",
  message = "",
  timestamp = null,
  alertId = null,
  taskId = null,
  targetRef = null,
  totalAlerts = 0,
  currentIndex = 0,
  onPrevious,
  onNext
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAutoDispatch_bak = async () => {
    try {
      setLoading(true);
      const response = await fetch(`http://lx.intelltai.com/web/index.php?c=site&a=entry&ctrl=errander&ac=order&op=dispatch_auto&do=web&m=we7_wmall&order_id=${taskId}`);
      
      if (!response.ok) {
        throw new Error('自动分配失败');
      }
      
      await handleConfirm('smart');
    } catch (error) {
      console.error('自动分配失败:', error);
      alert('自动分配失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  const handleAutoDispatch = () => {
    if (!taskId) return;
    window.open(`http://lx.intelltai.com/web/index.php?c=site&a=entry&ctrl=errander&ac=order&op=dispatch_auto&do=web&m=we7_wmall&order_id=${taskId}`, '_blank');
    handleConfirm('smart');
  };

  const handleSystemDispatch = () => {
    alert('请在后台分配任务');
    handleConfirm('system');
  };

  const handleConfirm = async (type) => {
    if (!alertId) {
      onOpenChange(false);
      return;
    }

    try {
      setIsClosing(true);
      const response = await fetch('http://lx.intelltai.com/mine/api/update_alert_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ alertId, type }),
      });

      if (!response.ok) throw new Error('更新失败');

      const data = await response.json();
      if (data.success) {
        setTimeout(() => {
          onOpenChange(false);
          setIsClosing(false);
        }, 500);
      } else {
        throw new Error(data.message || '更新失败');
      }
    } catch (error) {
      console.error('更新失败:', error);
      alert('操作失败，请重试');
      setIsClosing(false);
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-[9999] bg-black/50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-2xl w-[480px] mx-auto overflow-hidden">
        <div className="p-8">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3 text-2xl font-semibold text-red-600">
              <AlertTriangle className="h-8 w-8" />
              <h2>{title}</h2> {taskId}
            </div>
            <div className="text-sm text-gray-500">
              {totalAlerts > 0 && `${currentIndex + 1}/${totalAlerts}`}
            </div>
          </div>

          <div className="space-y-6">
            <p className="text-gray-900 text-lg whitespace-pre-wrap leading-relaxed">
              {message}
            </p>
            {timestamp && (
              <p className="text-sm text-gray-500">
                通知时间: {new Date(timestamp).toLocaleString('zh-CN')}
              </p>
            )}
          </div>
        </div>

        <div className="border-t border-gray-200 p-6 bg-gray-50">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={onPrevious}
              disabled={currentIndex === 0}
              className="flex items-center gap-1 px-3 py-1 text-gray-600 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronLeft className="h-4 w-4" />
              上一条
            </button>
            <button
              onClick={onNext}
              disabled={currentIndex === totalAlerts - 1}
              className="flex items-center gap-1 px-3 py-1 text-gray-600 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              下一条
              <ChevronRight className="h-4 w-4" />
            </button>
          </div>
          <div className="flex justify-between">
            <button
              onClick={handleAutoDispatch}
              disabled={loading}
              className="px-4 py-2 text-base font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors duration-200 disabled:opacity-50"
            >
              {loading ? '分配中...' : '智能调度'}
            </button>
            <div className="flex gap-4">
              <button
                onClick={handleSystemDispatch}
                className="px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 rounded-md transition-colors duration-200"
              >
                系统调度
              </button>
              <button
                onClick={() => handleConfirm('manual')}
                className="px-4 py-2 text-base font-medium text-white bg-red-600 hover:bg-red-700 rounded-md transition-colors duration-200"
              >
                已知晓
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;