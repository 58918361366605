import React, { useState, useEffect, useRef } from 'react';
import { Activity, Ambulance, Clock, UserCheck, User, Building } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { dashboardApi } from '../services/api';
import DataTable from './DataTable.js';
import AlertDialog from '../components/ui/alert-dialog';
import { AlertIcon } from '../components/ui/alert-icon';
import { Switch } from '../components/ui/switch';

const Dashboard = () => {
  const navigate = useNavigate();
  const alertIconRef = useRef(null);
  const staffTableRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [alerts, setAlerts] = useState([]);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  
  const [stats, setStats] = useState({
    activeTransports: 0,
    waitingTake: 0,
    waitingPatients: 0,
    completedToday: 0,
    overtimeTasks: 0,
    totalStaff: 0,
    departmentTasks: 0,
    emergencyAlerts: 0,
  });

  const [staffData, setStaffData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    id: null,
    title: '',
    message: '',
    timestamp: null,
    task_id: null
  });

  const [autoDispatch, setAutoDispatch] = useState(false);
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let timer;
    if (autoDispatch && alertOpen && countdown === null) {
      setCountdown(10);
      timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            // TODO: Call your auto dispatch API here
            setAlertOpen(false);
            return null;
          }
          return prev - 1;
        });
      }, 1000);
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [autoDispatch, alertOpen, countdown]);

  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      const data = await dashboardApi.getDashboardData();
      
      setStats({
        activeTransports: data.activeTransports,
        waitingTake: data.waitingTake,
        waitingPatients: data.waitingPatients,
        completedToday: data.completedToday,
        overtimeTasks: data.overtimeTasks,
        totalStaff: data.totalStaff,
        departmentTasks: data.departmentTasks,
        emergencyAlerts: data.alertsCount || 0,
      });

      setStaffData(data.staffData);
      setLastUpdated(new Date());

      if (data.emergencyAlerts?.length > 0) {
        setAlerts(data.emergencyAlerts);
        setAlertData({
          id: data.emergencyAlerts[0].id,
          title: data.emergencyAlerts[0].title,
          message: data.emergencyAlerts[0].message,
          timestamp: data.emergencyAlerts[0].timestamp,
          task_id: data.emergencyAlerts[0].task_id
        });
        setAlertOpen(true);
      }

      setError(null);
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      setError('无法获取仪表板数据');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
    const interval = setInterval(getDashboardData, 60000);
    
    if (staffTableRef.current) {
      staffTableRef.current.scrollIntoView({ behavior: 'smooth' });
      staffTableRef.current.focus();
    }
    
    return () => clearInterval(interval);
  }, []);

  const handlePreviousAlert = () => {
    if (currentAlertIndex > 0) {
      const newIndex = currentAlertIndex - 1;
      setCurrentAlertIndex(newIndex);
      setAlertData({
        id: alerts[newIndex].id,
        title: alerts[newIndex].title,
        message: alerts[newIndex].message,
        timestamp: alerts[newIndex].timestamp,
        task_id: alerts[newIndex].task_id
      });
    }
  };

  const handleNextAlert = () => {
    if (currentAlertIndex < alerts.length - 1) {
      const newIndex = currentAlertIndex + 1;
      setCurrentAlertIndex(newIndex);
      setAlertData({
        id: alerts[newIndex].id,
        title: alerts[newIndex].title,
        message: alerts[newIndex].message,
        timestamp: alerts[newIndex].timestamp,
        task_id: alerts[newIndex].task_id
      });
    }
  };

  const StatCard = ({ Icon, title, value, bgColor, textColor, onClick }) => (
    <div
      className={`${bgColor} rounded-xl shadow-xl p-6 cursor-pointer hover:opacity-90 opacity-75`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <Icon size={48} className={`${textColor} mr-4`} />
        <div>
          <div className={`${textColor} text-lg`}>{title}</div>
          <div className="text-white text-3xl font-bold">{value}</div>
        </div>
      </div>
    </div>
  );

  const statCardsConfig = [
    { 
      Icon: Clock, 
      title: '待接单', 
      value: stats.waitingTake, 
      bgColor: 'bg-orange-900', 
      textColor: 'text-orange-400', 
      link: '/task-list?status=1' 
    },
    { 
      Icon: Clock, 
      title: '待输送', 
      value: stats.waitingPatients, 
      bgColor: 'bg-teal-900', 
      textColor: 'text-teal-400', 
      link: '/task-list?status=2' 
    },
    { 
      Icon: Ambulance, 
      title: '输送中', 
      value: stats.activeTransports, 
      bgColor: 'bg-blue-900', 
      textColor: 'text-blue-400', 
      link: '/task-list?status=2&delivery_status=3' 
    },
    { 
      Icon: UserCheck, 
      title: '今日完成', 
      value: stats.completedToday, 
      bgColor: 'bg-green-900', 
      textColor: 'text-green-400', 
      link: '/task-list?status=3' 
    },
    { 
      Icon: User, 
      title: '在岗人员', 
      value: stats.totalStaff, 
      bgColor: 'bg-purple-900', 
      textColor: 'text-purple-400', 
      link: '/transporter-list' 
    },
    { 
      Icon: Building, 
      title: '科室任务分布图', 
      value: stats.departmentTasks, 
      bgColor: 'bg-indigo-900', 
      textColor: 'text-indigo-400', 
      link: '/department-dashboard' 
    }
  ];

  if (isLoading) {
    return <div className="text-white text-center text-2xl">加载中...</div>;
  }

  if (error) {
    return <div className="text-red-400 text-center text-2xl">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-cover bg-center p-6" style={{ backgroundImage: 'url(/bg.jpg)' }}>
      <AlertDialog 
        open={alertOpen}
        onOpenChange={setAlertOpen}
        title={`${alertData.title} ${countdown ? `(自动调度倒计时: ${countdown}秒)` : ''}`}
        message={alertData.message}
        timestamp={alertData.timestamp}
        alertId={alertData.id}
        taskId={alertData.task_id}
        targetRef={alertIconRef}
        totalAlerts={alerts.length}
        currentIndex={currentAlertIndex}
        onPrevious={handlePreviousAlert}
        onNext={handleNextAlert}
        variant={autoDispatch ? "countdown" : "default"}
        duration={autoDispatch ? 10000 : undefined}  // 10 seconds in milliseconds
      />

      <div className="flex justify-between items-center mb-8">
        <h1 className="text-white text-4xl font-bold">病患输送调度系统</h1>
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-2">
            <span className="text-white">自动调度</span>
            <Switch
              checked={autoDispatch}
              onCheckedChange={setAutoDispatch}
              className="bg-gray-600"
            />
          </div>
          <div 
            ref={alertIconRef} 
            className="cursor-pointer" 
            onClick={() => stats.emergencyAlerts > 0 && setAlertOpen(true)}
          >
            <AlertIcon count={stats.emergencyAlerts} />
          </div>
          
          <div className="text-yellow-400 text-2xl font-semibold">
            {currentTime.toLocaleTimeString('zh-CN', { hour12: false })}
          </div>
        </div>
      </div>

      {lastUpdated && (
        <div className="text-white text-lg mb-4">
          最后更新时间: {lastUpdated.toLocaleTimeString('zh-CN', { hour12: false })}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {statCardsConfig.map((card, index) => (
          <StatCard
            key={index}
            Icon={card.Icon}
            title={card.title}
            value={card.value}
            bgColor={card.bgColor}
            textColor={card.textColor}
            onClick={() => navigate(card.link)}
          />
        ))}
      </div>

      <div 
        ref={staffTableRef} 
        className="bg-gray-800 rounded-xl p-6 opacity-75" 
        tabIndex="-1"
      >
        <h2 className="text-white text-xl font-semibold mb-4">输送员位置和任务信息</h2>
        <DataTable staffData={staffData} />
      </div>
    </div>
  );
};

export default Dashboard;