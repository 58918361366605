// alert-icon.js
import React from 'react';
import { Bell } from 'lucide-react';

export const AlertIcon = ({ count = 10 }) => {
  // 这里移除 count === 0 的判断，让图标始终显示
  return (
    <div className="relative cursor-pointer hover:opacity-80 transition-opacity">
      <Bell className="h-8 w-8 text-red-400" />
      {count > 0 && (
        <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
          {count}
        </span>
      )}
    </div>
  );
};